import React, { useMemo } from 'react'
import Page from '../_library/Page'
import UserTable from './UserTable'
import { useSelector } from 'react-redux'
import Breadcrumbs from '../_library/Breadcrumbs'
import useFirestore from '../../hooks/useFirestore'
import { COMPANY_COLLECTION, TEAM_COLLECTION } from '../../_constants/globals'


const UserPage = () => {
  
  const companyHooks = useFirestore(COMPANY_COLLECTION)
  const companies = companyHooks.getDocs()
  const selectedCompanyId = useSelector(state => state.data.selectedCompanyId)
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const teams = teamHooks.getDocs()
  const selectedTeamId = useSelector(state => state.data.selectedTeamId)

  const selectedCompany = useMemo(() => companies?.find(c => c.id === selectedCompanyId), [companies, selectedCompanyId])
  const selectedTeam = useMemo(() => teams?.find(c => c.id === selectedTeamId), [teams, selectedTeamId])

  return (
    <Page title='Tableau de bord - Users'>
      <Breadcrumbs />
      <UserTable company={selectedCompany} team={selectedTeam} />
    </Page>
  )
}

export default UserPage
