import React, { useEffect, useMemo, useState } from 'react'
import { Box, FormControl, FormHelperText, Grid, IconButton, InputLabel, Link, MenuItem, OutlinedInput, Select, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import SubmitButton from '../_library/SubmitButton'
import { COMPANY_COLLECTION, TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useNavigate } from 'react-router-dom'
import { EDIT_USER } from '../../_constants/routes'
import { getRoles } from '../../_helpers/helpers'
import { Delete } from '@mui/icons-material'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'
import { arrayUnion } from 'firebase/firestore'
import { pickBy } from 'lodash'


const labelStyle = {
  position: 'static',
  transform: 'none',
  display: 'inline-block',
  width: 150,
  fontWeight: 600,
  lineHeight: '40px',
  verticalAlign: 'bottom',
}

const NewUserForm = () => {
  
  const { inviteUserAction } = useAuth()
  const navigate = useNavigate()
  const selectedCompanyId = useSelector(state => state.data.selectedCompanyId)
  const companyHook = useFirestore(COMPANY_COLLECTION)
  const companies = companyHook.getDocs()
  const teamHook = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHook.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [firstname, setFirstname] = useState(null)
  const [email, setEmail] = useState(null)
  const [company, setCompany] = useState(null)
  const [accessLevel, setAccessLevel] = useState(25)
  const [teams, setTeams] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})
  
  useEffect(() => {
    if (!company && companies) {
      selectedCompanyId
        ? setCompany(companies?.find(c => c.id === selectedCompanyId))
        : setCompany(companies[0])
    }
  }, [companies, selectedCompanyId])
  
  const allTeams = useMemo(() => dbTeams?.map(t => ({ ...t, managerPaths: t.managerRefs?.map(ref => ref.path) || [] })), [dbTeams])
  const companyTeams = useMemo(() => company && allTeams.filter(t => t.companyRef?.id === company.id), [company, allTeams])
  const availableTeams = useMemo(() => companyTeams?.filter(a => !teams?.some(t => t.id === a.id)), [companyTeams, teams])
  
  const handleSubmit = e => {
    e.preventDefault()
    setErrors({})
    if (!firstname) setErrors(val => ({ ...val, firstname: 'Renseignez un prénom' }))
    if (!email) setErrors(val => ({ ...val, email: 'Renseignez un email' }))
    if (firstname && email) {
      setSubmitted(true)
      inviteUserAction( {
        firstname,
        email,
        companyId: company.id,
        accessLevel,
      }, true)
        .then(data => {
          if (data) {
            const { id } = data
            return Promise.all(teams.map(t => teamHook.updateDoc(t.id, pickBy({
              memberRefs: arrayUnion(userHooks.getDocRef(id)),
              managerRefs: t.managerPaths.includes(str => str === 'newUser') && arrayUnion(userHooks.getDocRef(id)),
            }))))
              .then(() => navigate(EDIT_USER.replace(':id', id), { replace: true }))
          }
          else return null
        })
        .finally(() => setSubmitted(false))
    }
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item md={8}>
          <Stack spacing={3}>
            <FormControl sx={{ display: 'block' }}>
              <InputLabel id='input-firstname-label' sx={labelStyle}>Prénom</InputLabel>
              <OutlinedInput
                value={firstname || ''}
                onChange={e => setFirstname(e.target.value)}
                variant='outlined'
                size='small'
                disabled={submitted}
                error={!!errors.firstname}
                sx={{ display: 'inline-block', width: '200px' }}
              />
              {!!errors.firstname && <FormHelperText sx={{ ml: '150px' }}>{errors.firstname}</FormHelperText>}
            </FormControl>
            <FormControl sx={{ display: 'block' }}>
              <InputLabel id='input-email-label' sx={labelStyle}>Email</InputLabel>
              <OutlinedInput
                value={email || ''}
                onChange={e => setEmail(e.target.value)}
                variant='outlined'
                size='small'
                disabled={submitted}
                error={!!errors.email}
                sx={{ display: 'inline-block', width: '200px' }}
              />
              {!!errors.email && <FormHelperText sx={{ ml: '150px' }}>{errors.email}</FormHelperText>}
            </FormControl>
            <FormControl sx={{ display: 'block' }}>
              <InputLabel id='select-company-label' sx={labelStyle}>Client</InputLabel>
              {company && <Select
                labelId='select-company-label'
                id='select-company'
                value={company.id}
                onChange={e => setCompany(companies.find(c => c.id === e.target.value))}
                size='small'
                disabled={submitted}
              >
                {companies.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
              </Select>}
            </FormControl>
            <FormControl sx={{ display: 'block' }}>
              <InputLabel id='select-access-label' sx={labelStyle}>Niveau d&apos;accès</InputLabel>
              <Select
                labelId='select-access-label'
                id='select-manager'
                value={accessLevel || 0}
                onChange={e => setAccessLevel(e.target.value)}
                size='small'
                disabled={submitted}
              >
                {getRoles().map(r => <MenuItem key={r.accessLevel} value={r.accessLevel}>{r.title}</MenuItem>)}
              </Select>
            </FormControl>
            {availableTeams && teams?.map((team, index) => <Box key={index}>
              <FormControl sx={{ display: 'block' }}>
                <InputLabel id='select-team-label' sx={labelStyle}>Equipe</InputLabel>
                <Select
                  labelId='select-team-label'
                  id='select-team'
                  value={team.id || ''}
                  onChange={e => setTeams(prev => prev.map((t, i) => i === index ? allTeams.find(needle => needle.id === e.target.value) : t))}
                  size='small'
                  disabled={submitted}
                >
                  <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
                  {availableTeams.map(team =>
                    <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>,
                  )}
                </Select>
                <IconButton onClick={() => setTeams(prev => prev.filter(t => t.id !== team.id))} sx={{ ml: 3 }}><Delete /></IconButton>
              </FormControl>
              <FormControl sx={{ display: 'block' }}>
                <InputLabel id='select-role-label' sx={labelStyle}>Role</InputLabel>
                <Select
                  labelId='select-role-label'
                  id='select-role'
                  value={team.managerPaths?.some(p => p.includes('newUser')) ? 'manager' : 'member'}
                  onChange={e => setTeams(prev => {
                    if (e.target.value === 'manager')
                      prev[index].managerPaths.push('newUser')
                    else
                      prev[index].managerPaths.filter(p => !p.includes('newUser'))
                    return [...prev]
                  })}
                  size='small'
                  disabled={submitted}
                >
                  <MenuItem value='member'>Member</MenuItem>
                  <MenuItem value='manager'>Manager</MenuItem>
                </Select>
              </FormControl>
            </Box>)}
            {availableTeams?.length > 0 && <Link
              onClick={() => setTeams(prev => [...prev, availableTeams[0]])}
              aria-disabled={submitted}
              sx={{
                mt: 1,
                ml: '150px',
                cursor: 'pointer',
                textDecoration: 'underline',
                width: 'fit-content',
              }}
            >Ajouter à une{!!teams.length && ' autre'} équipe</Link>}
          </Stack>
        </Grid>
        <Grid item md={4}>
          <SubmitButton disabled={submitted} />
        </Grid>
      </Grid>
    </form>
  )
}

export default NewUserForm
