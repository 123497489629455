import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { HOME } from '../../_constants/routes'
import { Box, Link } from '@mui/material'
import Menu from './Menu'
import logo from '../../assets/logo-boussole.svg'
import useAuth from '../../hooks/useAuth'


const logoStyle = {
  margin: { xs: '5px', sm: '15px' },
  height: '50px',
  maxWidth: { xs: '67%', sm: '100%' },
  maxHeight: { xs: '94%', sm: '100%' },
}

export const TopBar = () => {

  const navigate = useNavigate()
  const profile = useAuth().getProfile()
  const logoUrl = useMemo(() => profile?.company?.logoUrl, [profile])

  return <Box sx={{
    height: '80px',
    top: 0,
    left: 0,
    bgcolor: 'white',
    width: '100%',
    zIndex: 10,
    boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.08)',
    position: 'relative',
  }}>
    <Link onClick={() => navigate(HOME)}>
      {logoUrl && <Box component='img' src={logoUrl} alt='Consultant Logo' sx={logoStyle} />}
      <Box component='img' src={logo} alt='Wake up Logo' sx={logoStyle} />
    </Link>
    <Menu />
  </Box>
}

export default TopBar
